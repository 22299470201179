var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","loading":_vm.loading,"value":_vm.propostas,"dataKey":"id","filters":_vm.filtros,"globalFilterFields":[
            'mesReferencia',
            'anoReferencia',
            'contrato',
            'tipo',
            'statusProposta.nome' ],"filterDisplay":"menu","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":[5, 10, 25],"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} Propostas","responsiveLayout":"stack"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex flex-column sm:flex-row"},[_c('span',{staticClass:"p-input-icon-left mb-2 mr-2"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"p-button-outlined mb-2",attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})],1)]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" Nenhuma proposta encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true}])},[_c('Column',{staticClass:"col-4",attrs:{"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(('0' + slotProps.data.mesReferencia).slice(-2))+"/"+_vm._s(slotProps.data.anoReferencia)+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"tipo","header":"Tipo"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.tipoProposta(data.tipo))+" ")]}}])}),_c('Column',{attrs:{"header":"Solicitação"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var data = ref.data;
return [_vm._v(" "+_vm._s(data.on_line ? 'Online' : 'Presencial')+" ")]}}])}),(_vm.modulo === 'servidor')?_c('Column',{attrs:{"field":"rubrica.consignataria.razaoSocial","header":"Consignataria"}}):_vm._e(),_c('Column',{attrs:{"field":"contrato","header":"Contrato"}}),_c('Column',{attrs:{"header":"Natureza"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var data = ref.data;
return [_vm._v(" "+_vm._s(data.natureza ? data.natureza.nome : '-')+" ")]}}])}),_c('Column',{attrs:{"field":"prazoTotal","header":"Prazo"}}),_c('Column',{attrs:{"header":"Valor da Parcela"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-end flex-wrap card-container"},[_c('div',{staticClass:"flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.valorParcela))+" ")])])]}}])}),_c('Column',{attrs:{"sortable":true,"field":"statusProposta.nome","header":"Situação"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var data = ref.data;
return [(
                  data.statusProposta.nome === 'Pendente' ||
                  data.statusProposta.id === 5
                )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip pendente",attrs:{"label":"Pendente"}}):_vm._e(),(data.statusProposta.nome === 'Aprovada')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip ativa",attrs:{"label":"Aprovada"}}):_vm._e(),(data.statusProposta.nome === 'Cancelada')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(
                  data.statusProposta.nome === 'Reprovada' ||
                  data.statusProposta.id === 6
                )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Reprovada"}}):_vm._e()]}}])}),_c('Column',{attrs:{"id":"acoes","header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-success",attrs:{"icon":"pi pi-eye"},on:{"click":function($event){return _vm.exibirDetalheProposta(slotProps.data)}}})]}}])})],1)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }