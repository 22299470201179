import { defineStore } from 'pinia'

export const servidorStore = defineStore('servidor', {
  state: () => {
    return {
      dados: {
        servidor: {
          nome: '',
          email: '-',
          celular: '-',
        },
        vinculo: {},
        entidade: {},
        orgao: {},
        dataAdmissao: null,
        dataAfastamento: null,
      },
      configuracoes: [],
      validacao: {
        value: '',
        severity: '',
        icon: '',
        motivos: [],
        servidorApto: false,
      },
    }
  },

  getters: {
    getDados(state) {
      return state.dados
    },

    getServidor(state) {
      return state.dados.servidor
    },
  },

  actions: {
    checkValidation() {
      this.validacao.motivos = []
      this.validacao.servidorApto = true
      this.validacao.value = 'O Servidor está apto a consignar.'
      this.validacao.severity = 'success'
      this.validacao.icon = 'pi pi-check'

      if (this.dados.bloqueada) {
        this.validacao.servidorApto = false
        this.validacao.motivos.push('MATRÍCULA BLOQUEADA')
      }

      if (this.dados.servidor.falecido) {
        this.validacao.servidorApto = false
        this.validacao.motivos.push('SERVIDOR FALECIDO')
      }

      if (this.dados.exonerado) {
        this.validacao.servidorApto = false
        this.validacao.motivos.push('SERVIDOR EXONERADO')
      }

      // if (this.dados.servidor.ativo === false) {
      //   this.validacao.servidorApto = false
      //   this.validacao.motivos.push('SERVIDOR NÃO ESTÁ ATIVO')
      // }

      if (this.dados.afastado === true) {
        this.validacao.servidorApto = false
        this.validacao.motivos.push('SERVIDOR AFASTADO')
      }

      // if (this.dados.ativo === false) {
      //   this.validacao.servidorApto = false
      //   this.validacao.motivos.push('MATRÍCULA NÃO ESTÁ ATIVA')
      // }

      if (this.validacao.servidorApto) {
        this.validacao.value = 'O Servidor está apto a consignar.'
        this.validacao.severity = 'success'
        this.validacao.icon = 'pi pi-check'
      } else {
        this.validacao.value = 'O Servidor não está apto a consignar.'
        this.validacao.severity = 'danger'
        this.validacao.icon = 'pi pi-times'
      }
    },
  },
})
