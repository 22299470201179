<script>
import PropostaList from '@/components/shared/proposta/PropostaList.vue'

export default {
  extends: PropostaList,

  data() {
    return {
      modulo: 'servidor',
    }
  },
}
</script>
